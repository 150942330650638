import React, { useState, useEffect, useContext } from 'react'
import { context } from '~/layouts'
import { PageProps } from 'gatsby'
import {
  Head,
  Footer,
  WhatsApp,
  SearchPost,
  PinnedArticles,
  MostAccessed,
  ContainerPosts,
  Newsletter,
  Loading,
} from '~/components'
import * as st from '~/assets/styl/Blog.module.styl'
import { useApiBlog as api } from '~/siteApi'

const Blog = ({ location }: PageProps) => {
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [mostViewed, setMostViewed] = useState([])
  const postsPerPage = 6
  const [totalPages, setTotalPages] = useState(0)
  const { currentPage } = useContext(context)

  const getAllPosts = async () => {
    const request = await api.requestData(
      'get',
      `posts?_embed&per_page=${postsPerPage}&page=${currentPage}`
    )
    setTotalPages(request?.headers['x-wp-totalpages'])
    setPosts(request.data)
  }

  const mostViewedPosts = async () => {
    try {
      const request = await api.requestData('get', 'posts')
      const sortedPosts = request.data.sort(
        (a, b) => b.tk_post_counter - a.tk_post_counter
      )
      const topTwoPosts = sortedPosts.slice(0, 2)
      setMostViewed(topTwoPosts)
    } catch (error) {
      console.error('Erro ao buscar os posts mais visualizados:', error)
    }
  }

  const getAllCategories = async () => {
    const request = await api.requestData('get', 'categories?per_page=100')
    setCategories(request.data)
  }

  const getCategoryNameById = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId)
    return category ? category.name : 'Categoria desconhecida'
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAllCategories()
      await getAllPosts()
      await mostViewedPosts()
    }

    fetchData()
  }, [currentPage])

  return (
    <>
      <Head
        location={location}
        title={`Aikon - ${process.env.GATSBY_SITE_NAME}`}
      />
      <main className={st.core}>
        <div className={st.grid}>
          <h1>Blog Aikon</h1>
          {(posts && posts.length > 0 && (
            <>
              <PinnedArticles
                posts={posts}
                categoriesName={getCategoryNameById}
              />
              <SearchPost
                categories={categories}
                categoriesName={getCategoryNameById}
              />
              {/* <MostAccessed
                posts={mostViewed}
                categoriesName={getCategoryNameById}
              /> */}
              <ContainerPosts
                totalPages={totalPages}
                posts={posts}
                categoriesName={getCategoryNameById}
              />
            </>
          )) || <Loading visible />}
          <Newsletter categorieOrSearch={false} />
        </div>
      </main>
      <Footer />
      <WhatsApp />
    </>
  )
}

export default Blog
